<template>
  <div id="mapa-coropletico-kpis-by-municipio"></div>
</template>

<script>

import L from 'leaflet';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import Gradient from 'javascript-color-gradient';
import MunicipiosService from '@/services/MunicipiosService';
import geocoderMixins from '@/mixins/leaflet/geocoderMixins';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

const parseNumberToFloatBrIgnoringNaN = value => {
  const parsedValue = parseFloat(value).toLocaleString('pt-BR');
  return parsedValue === 'NaN' ? value : parsedValue;
}; 

export default {
  name: "DashboardServicosVisaoMunicipiosKpisMap",
  mixins: [
    geocoderMixins
  ],
  props: {
    dadosKpis: {
      type: Array,
      required: true  
    },
    corValoresBaixosKpi: {
      type: String,
      default: '#76FF03'
    },
    corValoresAltosKpi: {
      type: String,
      default: '#F44336'
    },
    corValoresZerados: {
      type: String,
      default: '#9E9E9E'
    },
    numCores: {
      type: Number,
      default: 4
    }
  },
  data: () => ({
    kpis: [
      "pendentes",
      "psa",
      "pat",
      "suspensos",
      "concluidos",
      "psfp",
      "patc",
      "compensacoes"
    ],
    kpisLabels: {
      "pendentes": "Pendentes",
      "psa": "PSA",
      "pat": "PAT",
      "suspensos": "Suspensos",
      "concluidos": "Concluídos",
      "psfp": "PSFP",
      "patc": "PATC",
      "compensacoes": "Compensações"
    },
    mapaId: 'mapa-coropletico-kpis-by-municipio',
    oldCodigosMunicipios: [],
    map: null,
    layerControl: null,
    infoControl: null,
    legendControl: null,
    kpiSelecionado: 'pendentes',
    dadosGeograficosMunicipios: {},
    mapboxDefaultLayerId: null
  }),
  computed: {
    dadosKpisByMunicipio() {
      let formattedDados = {};
      
      this.kpis.forEach(kpi => {
        formattedDados[kpi] = {};
        this.dadosKpis.forEach(dados => {
          formattedDados[kpi][dados.codigo_ibge] = {
            codigo_ibge: dados.codigo_ibge,
            nome_municipio: dados.nome_municipio,
            valor: dados[kpi]
          };
        });
      });

      return formattedDados;
    },
    kpiPercentual() {
      return ["psa"].includes(this.kpiSelecionado);
    },
    dadosKpisIndexedByMunicipio() {
      let indexedBy = {};
      this.kpis.forEach(kpi => {
        indexedBy[kpi] = {};
        Object.values(this.dadosKpisByMunicipio[kpi]).forEach(dado => indexedBy[kpi][dado['codigo_ibge']] = dado);
      });
      return indexedBy;
    },
    todosMunicipios() {
      let todosMunicipios = {};
      this.kpis.forEach(kpi => Object.values(this.dadosKpisByMunicipio[kpi]).forEach(dado => todosMunicipios[dado['codigo_ibge']] = dado['nome_municipio']));
      return todosMunicipios;
    },
    codigosMunicipios() {
      return Object.keys(this.todosMunicipios);
    },
    dadosKpiSelecionado() {
      return this.dadosKpisIndexedByMunicipio[this.kpiSelecionado];
    },
    menorValorKpi() {
      const dadosKpiSelecionado = Object.values(this.dadosKpiSelecionado);
      const valor = dadosKpiSelecionado && dadosKpiSelecionado[0] && dadosKpiSelecionado[0]['valor'] ? dadosKpiSelecionado[0]['valor'] : 0; 
      let menorValorKpi = Number(valor);
      dadosKpiSelecionado.forEach(({ valor }) => {
        if (Number(valor) < menorValorKpi) menorValorKpi = Number(valor);
      });
      return menorValorKpi > 0 ? 0 : menorValorKpi;
    },
    maiorValorKpi() {
      if (this.kpiPercentual) return 100;
      const dadosKpiSelecionado = Object.values(this.dadosKpiSelecionado);
      const valor = dadosKpiSelecionado && dadosKpiSelecionado[0] && dadosKpiSelecionado[0]['valor'] ? dadosKpiSelecionado[0]['valor'] : 0; 
      let maiorValorKpi = Number(valor);
      dadosKpiSelecionado.forEach(({ valor }) => {
        if (Number(valor) > maiorValorKpi) maiorValorKpi = Number(valor);
      });
      return maiorValorKpi;
    },
    intervaloEntreCores() {
      return this.cores.length ? (this.maiorValorKpi - this.menorValorKpi) / this.cores.length : 0;
    },
    cores() {
      if (
        !this.corValoresBaixosKpi || 
        !this.corValoresAltosKpi || 
        !this.numCores
      ) return [];
      const cores = new Gradient()
        .setColorGradient(
          this.corValoresBaixosKpi, 
          this.corValoresAltosKpi
        )
        .setMidpoint(this.numCores-1)
        .getColors();
      return [
        this.corValoresBaixosKpi,
        ...cores
      ];
    },
    limites() {
      let limites = [];
      let limite = this.menorValorKpi + this.intervaloEntreCores;
      this.cores.forEach(() => {
        limites.push(limite);
        limite += this.intervaloEntreCores;
      });
      return limites;
    },
    sumLimites() {
      return this.limites.reduce((prev, item) => prev + item, 0);
    }
  },
  mounted() {
    this.configuraMapa(8);

    this.map.spin(true);
    this.getDadosGeograficos()
  },
  methods: {
    configuraMapa(zoom) {
      const defaultLayer = mapboxConfig.getDefaultLayer();
      this.mapboxDefaultLayerId = L.stamp(defaultLayer);
      this.map = L.map(this.mapaId, {
        fullscreenControl: true,
        layers: [
          defaultLayer
        ],
        zoom,
      });
      this.layerControl = L.control.layers(mapboxConfig.getBaseLayers());
      this.layerControl.addTo(this.map);
      this.createGeocoderControl(L, this.map).addTo(this.map);
      this.addSelectControl();
      // Posiciona o mapa inicialmente de forma a mostrar o mapa do Brasil
      this.map.setView([
        -13.068776734357694, 
        -50.75357385334945 
      ], 4);
    },
    addSelectControl() {
      L.control
        .select({
          position: 'topleft',
          selectedDefault: this.kpiSelecionado,
          items: [
            { value: "pendentes", label: "Pendentes" },
            { value: "psa", label: "PSA" },
            { value: "pat", label: "PAT" },
            { value: "suspensos", label: "Suspensos" },
            { value: "concluidos", label: "Concluídos" },
            { value: "psfp", label: "PSFP" },
            { value: "patc", label: "PATC" },
            { value: "compensacoes", label: "Compensações" }
          ],
          onSelect: (kpiSelecionado) => {
            this.kpiSelecionado = kpiSelecionado;
          }
        })
        .addTo(this.map);
    },
    plotaMapaCoropletico() {
      
      let layers = [];
      this.removeLayers();
      this.setInfoControl();
      this.setLegendControl();
      this.codigosMunicipios.forEach(codigoMunicipio => {
        
        if (!(
          this.dadosGeograficosMunicipios &&
          this.dadosGeograficosMunicipios[codigoMunicipio] &&
          this.dadosGeograficosMunicipios[codigoMunicipio][0] &&
          this.dadosGeograficosMunicipios[codigoMunicipio][0].geojson    
        )) return false;

        if (!(
          this.dadosKpiSelecionado &&
          this.dadosKpiSelecionado[codigoMunicipio]
        )) return false;
        const dadosKpiMunicipio = this.dadosKpiSelecionado[codigoMunicipio];
        const valorIndicador = dadosKpiMunicipio && dadosKpiMunicipio['valor'] ? dadosKpiMunicipio['valor'] : 0;

        let layer;
        const highlightFeature = e => {
          var layer = e.target;
          layer.setStyle({
            weight: 2,
            color: '#666',
            dashArray: ''
          });
          if (
            !L.Browser.ie && 
            !L.Browser.opera && 
            !L.Browser.edge
          ) {
            layer.bringToFront();
          }
          this.infoControl.update(dadosKpiMunicipio);
        }
        const resetHighlight = e => {
          layer.resetStyle(e.target);
          this.infoControl.update();
        }
        const zoomToFeature = e => this.map.fitBounds(e.target.getBounds());
        const getCorPorValorIndicador = valor => {
          if (valor === 0) return this.corValoresZerados;
          let cor = this.cores[0] ?? this.corValoresBaixosKpi;
          for (let i = 0; i < this.cores.length; i++) {
            if (Number(valor) <= Number(this.limites[i])) {
              cor = this.cores[i];
              break;
            }
          }
          return cor;
        } 
        
        layer = L.geoJSON(JSON.parse(this.dadosGeograficosMunicipios[codigoMunicipio][0].geojson), {
          style: () => {
            return {
              fillColor: getCorPorValorIndicador(valorIndicador),
              fillOpacity: 0.9,
              color: 'white',
              opacity: 1,
              weight: 2,
              dashArray: '3'
            };
          },
          onEachFeature: (feature, layer) => {
            layer.on({
              mouseover: highlightFeature,
              mouseout: resetHighlight,
              click: zoomToFeature
            });
          }
        });
        layers.push(layer);
      });
      const featureGroup = L.featureGroup(layers);
      featureGroup.addTo(this.map);
      this.map.fitBounds(featureGroup.getBounds());
    },
    removeLayers() {
      this.map.eachLayer(layer => {
        const layerdId = L.stamp(layer);
        if (this.mapboxDefaultLayerId !== layerdId) this.map.removeLayer(layer);
      });
    },
    msgPopup(dadosKpiMunicipio) {
      let valorTxt = dadosKpiMunicipio && dadosKpiMunicipio['valor'] ? dadosKpiMunicipio['valor'] : 0;
      valorTxt = parseNumberToFloatBrIgnoringNaN(valorTxt);
      if (this.kpiPercentual) valorTxt += '%'; 
      return `
<b>IBGE:</b> ${dadosKpiMunicipio['codigo_ibge']}<br/>
<b>Município:</b> ${dadosKpiMunicipio['nome_municipio']}<br/>
<b>${this.kpisLabels[this.kpiSelecionado]}:</b> ${valorTxt}
`;
    },
    setInfoControl() {
      if (
        this.infoControl && 
        this.infoControl._div
      ) {
        this.infoControl._div.remove();
      }
      this.infoControl = L.control();
      this.infoControl.onAdd = function() {
        this._div = L.DomUtil.create('div', 'mapa-coropletico-kpis-by-municipio-info');
        this.update();
        return this._div;
      };
      const { msgPopup, kpiSelecionado, kpisLabels } = this;
      this.infoControl.update = function(props) {
        this._div.innerHTML = (props ? msgPopup(props) : kpisLabels[kpiSelecionado]);
      };
      this.infoControl.addTo(this.map);  
    },
    setLegendControl() {
      if (
        this.legendControl && 
        this.legendControl._div
      ) {
        this.legendControl._div.remove();
      }
      this.legendControl = L.control({ 
        position: 'bottomright' 
      });
      const { 
        kpiPercentual, 
        corValoresZerados,
        limites, 
        sumLimites,
        cores 
      } = this;
      this.legendControl.onAdd = function (map) {
        this._div = L.DomUtil.create('div', 'mapa-coropletico-kpis-by-municipio-info mapa-coropletico-kpis-by-municipio-legend');
        let v = 0;
        if (kpiPercentual) v += '%';
        let html = `<i style="background:${corValoresZerados}"></i> = ${v}<br>`;
        if (sumLimites) {
          limites.forEach((limite, index) => {
            let cor = cores[index];
            v = parseFloat(limite).toLocaleString('pt-BR');
            if (kpiPercentual) v += '%';
            html += `<i style="background:${cor}"></i> <= ${v}<br>`;
          });
        }
        this._div.innerHTML = html;
        return this._div;
      };
      this.legendControl.addTo(this.map);
    },
    getDadosGeograficos() {
      if (this.oldCodigosMunicipios.toString() === this.codigosMunicipios.toString()) {
        // Evita carregar a requisição pesada desnecessáriamente
        try {
          this.plotaMapaCoropletico();
        } catch (e) {
          console.log(e);
        }
        this.map.spin(false);

        return;
      }
      this.oldCodigosMunicipios = this.codigosMunicipios;

      MunicipiosService
        .getDadosGeograficos(this.codigosMunicipios)
        .then(({ 
          dados_geograficos_municipios
        }) => {
          this.dadosGeograficosMunicipios = dados_geograficos_municipios;
        })
        .catch(err => {
          console.error(err);
          this.$toast.error('Ocorreu um erro ao tentar obter os dados geográficos dos municípios.', '', { position: 'topRight' });
        })
        .finally(() => this.map.spin(false));
    },
  },
  watch: {
    codigosMunicipios() {
      this.map.spin(true);
      this.getDadosGeograficos();
    },
    dadosGeograficosMunicipios() {
      this.map.spin(true);
      try {
        this.plotaMapaCoropletico();
      } catch (e) {
        console.log(e);
      }
      this.map.spin(false);
    },
    kpiSelecionado() {
      this.map.spin(true);
      setTimeout(() => {
        try {
          this.plotaMapaCoropletico();
        } catch (e) {
          console.log(e);
        }
        this.map.spin(false);
      }, 2000);
    }
  }
}

</script>

<style>

#mapa-coropletico-kpis-by-municipio {
  min-height: 425px;
  z-index: 0;
}

.mapa-coropletico-kpis-by-municipio-info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255,255,255,0.8);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 5px;
}

.mapa-coropletico-kpis-by-municipio-info h4 {
  margin: 0 0 5px;
  color: #777;
}

.mapa-coropletico-kpis-by-municipio-legend {
  line-height: 18px;
  color: #555;
  max-height: 250px;
  overflow-y: auto;
}

</style>